/**
 * router.js
 *
 * Main router file for the login page. Each component related to a route
 * is dynamically imported on demand resulting in an initial smaller
 * bundle size and faster initial load. This is achievable through
 * webpack's code splitting feature.
 *
 * No additional setup is needed with vue as async components are
 * built-in to the library.
 */
import Vue from 'vue';
import Router from 'vue-router';
import Login from './containers/Login.vue';

/**
 * Use the import function returned in a function to use it as
 * a dynamic component. A separate chunk will be created automatically.
 *
 * https://vuejs.org/v2/guide/components-dynamic-async.html#Async-Components
 * https://webpack.js.org/guides/code-splitting/#dynamic-imports
 */
const LoginForm = () => import('./components/LoginForm.vue');
const TwoFactor = () => import('./components/TwoFactor.vue');
const ForgotPassword = () => import('./components/ForgotPassword.vue');
const ChangePassword = () => import('./components/ChangePassword.vue');
const ChangePasswordSuccess = () =>
  import('./components/ChangePasswordSuccess.vue');
const UnlockAccount = () => import('./components/UnlockAccount.vue');
const CheckEmail = () => import('./components/CheckEmail.vue');
const SsoLoginForm = () => import('./components/SsoLoginForm.vue');
const SsoLoginError = () => import('./components/SsoLoginError.vue');

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '',
      component: Login,
      props: () => {
        return {
          title: 'Log In',
          cssClass: 'ntv-login--main'
        };
      },
      children: [
        {
          path: '',
          component: LoginForm
        }
      ]
    },
    {
      path: '/login',
      component: Login,
      props: (route) => {
        let title = '';
        let cssClass = '';
        let showText = true;
        switch (removeTrailingSlash(route.path)) {
          case '/login':
            title = 'Log In';
            cssClass = 'ntv-login--main';
            showText = false;
            break;
          case '/login/two-factor':
            title = 'Authentication';
            cssClass = 'ntv-login--two-factor';
            break;
          case '/login/unlock':
            title = 'Unlock Account';
            cssClass = 'ntv-login--unlock';
            break;
          case '/login/change-password':
            title = 'New Password';
            cssClass = 'ntv-login--change-password';
            break;
          case '/login/change-password/success':
            title = 'New Password Set';
            cssClass = 'ntv-login--change-password';
            break;
          case '/login/forgot-password':
            title = 'Forgot Password';
            cssClass = 'ntv-login--forgot-password';
            break;
          case '/login/forgot-password/check-email':
            title = 'Check Your Email';
            cssClass = 'ntv-login--forgot-password';
            break;
          case '/login/sso':
            title = 'Log In';
            showText = false;
            break;
          case '/login/sso/error':
            title = '';
            showText = false;
            break;
          default:
            break;
        }

        return {
          title,
          cssClass,
          showText
        };
      },
      children: [
        {
          path: '/',
          component: LoginForm
        },
        {
          path: 'unlock',
          component: UnlockAccount
        },
        {
          path: 'change-password',
          component: ChangePassword
        },
        {
          path: 'change-password/success',
          component: ChangePasswordSuccess
        },
        {
          path: 'forgot-password',
          component: ForgotPassword
        },
        {
          path: 'forgot-password/check-email',
          component: CheckEmail
        },
        {
          path: 'two-factor',
          name: 'two-factor',
          component: TwoFactor,
          props: true
        },
        {
          path: 'sso',
          component: SsoLoginForm
        },
        {
          path: 'sso/error',
          component: SsoLoginError
        }
      ]
    },
    {
      path: '/logout',
      component: Login,
      children: [
        {
          path: '',
          component: LoginForm
        }
      ]
    }
  ]
});

function removeTrailingSlash(url) {
  return url.replace(/\/$/, '');
}

// keep the return_url + hash between login routes
router.beforeEach((to, from, next) => {
  if (from.query.return_url && !to.query.return_url) {
    next({ ...to, query: from.query, hash: from.hash });
  } else {
    next();
  }
});

export default router;
