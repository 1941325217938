import Vue from 'vue';
import { installTooltip } from 'nucleus-ui';
import App from './App.vue';
import router from './router';

installTooltip(Vue);

new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app');
