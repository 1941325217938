<script>
import { NtvCard, NtvText, NtvNotification } from 'nucleus-ui';

import './Login.scss';

export default {
  name: 'NtvLogin',

  components: {
    NtvCard,
    NtvText,
    //eslint-disable-next-line
    NtvNotification
  },

  props: {
    title: {
      type: String,
      default: ''
    },
    cssClass: {
      type: String,
      default: ''
    },
    showUserIcon: {
      type: Boolean,
      default: false
    },
    showText: {
      type: Boolean,
      default: true
    }
  },

  created() {
    // Make the vue router globally accessible so we can
    // access it when running our E2E tests.
    window.__router__ = this.$router;
  }
};
</script>

<template>
  <div :class="['ntv-login', cssClass]">
    <div :class="['ntv-login__panel']">
      <ntv-card shadow="none">
        <div class="ntv-login__title">
          <img
            class="max-w-xs w-full mb-2xl"
            src="/static/images/logo-white-text.svg"
            alt="logo"
            :width="320"
            :height="80"
          />
          <ntv-text
            v-if="showText"
            class="mt-2xl"
            :text="title"
            size="6xl"
            color="white"
            weight="normal"
          />
        </div>
        <router-view />
      </ntv-card>
    </div>
  </div>
</template>
